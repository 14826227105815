import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import Rozdzial from '../comp/rozdzial'
import Slider_d from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'





export default function Print() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/magic/5/slider/1@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/magic/5/slider/2@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }


                slider_3: file(relativePath: { eq: "projekty/magic/5/slider/3@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/magic/5/slider/4@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_5: file(relativePath: { eq: "projekty/betard/5/slider2/1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_6: file(relativePath: { eq: "projekty/betard/5/slider2/2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_7: file(relativePath: { eq: "projekty/betard/5/slider2/3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_8: file(relativePath: { eq: "projekty/speedway/5/slider/1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_9: file(relativePath: { eq: "projekty/speedway/5/slider/2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_10: file(relativePath: { eq: "projekty/speedway/5/slider/3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_11: file(relativePath: { eq: "projekty/speedway/5/slider/4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_d1: file(relativePath: { eq: "projekty/betard/5/slider_d1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                    }
                    }
    
                slider_d2: file(relativePath: { eq: "projekty/betard/5/slider_d2@2x.jpg" }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/speedway/5/okladka@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty: file(relativePath: { eq: "projekty/betard/4/skala_produkty@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty_animacja: file(relativePath: { eq: "projekty/betard/4/produkty_animacja@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                przeswietlenia: file(relativePath: { eq: "projekty/betard/4/przeswietlenia@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta1: file(relativePath: { eq: "projekty/betard/5/oferta@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

               

                oferta2: file(relativePath: { eq: "projekty/betard/5/oferta2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta3: file(relativePath: { eq: "projekty/betard/5/oferta3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta4: file(relativePath: { eq: "projekty/betard/5/oferta4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta5: file(relativePath: { eq: "projekty/betard/5/oferta5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta6: file(relativePath: { eq: "projekty/betard/5/oferta6@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta7: file(relativePath: { eq: "projekty/betard/5/oferta7@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                oferta8: file(relativePath: { eq: "projekty/betard/5/oferta8@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp1: file(relativePath: { eq: "projekty/betard/5/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/betard/5/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp3: file(relativePath: { eq: "projekty/betard/5/komp3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp4: file(relativePath: { eq: "projekty/betard/5/komp4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp5: file(relativePath: { eq: "projekty/betard/5/komp5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp6: file(relativePath: { eq: "projekty/betard/5/komp6@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp7: file(relativePath: { eq: "projekty/betard/5/komp7@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp8: file(relativePath: { eq: "projekty/betard/5/komp8@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp9: file(relativePath: { eq: "projekty/betard/5/komp9@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp10: file(relativePath: { eq: "projekty/betard/5/komp10@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp11: file(relativePath: { eq: "projekty/betard/5/komp11@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp12: file(relativePath: { eq: "projekty/betard/5/komp12@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp13: file(relativePath: { eq: "projekty/betard/5/komp13@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp14: file(relativePath: { eq: "projekty/betard/5/komp14@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full1: file(relativePath: { eq: "projekty/magic/5/full1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full2: file(relativePath: { eq: "projekty/magic/5/full2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                kol_tekst1: file(relativePath: { eq: "projekty/betard/5/kol_tekst1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }



            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]

    const foto2 = [data.slider_5.childImageSharp.fluid, data.slider_6.childImageSharp.fluid, data.slider_7.childImageSharp.fluid]

    const slider_d = [data.slider_d1.childImageSharp.fluid, data.slider_d2.childImageSharp.fluid]

    const slider_d2 = [data.slider_8.childImageSharp.fluid, data.slider_9.childImageSharp.fluid, data.slider_10.childImageSharp.fluid, data.slider_11.childImageSharp.fluid]
    
    
    return(
        <>

        <Rozdzial tytul="EVENT" invert={false} tekst="2020 Wrocław Speedway Grand Prix wiązało się dla nas nie tylko z przygotowaniem materiałów do eventu - odpowiadaliśmy także za stworzenie strefy kibica Betard. W organizowanym przez nas wydarzeniu udział wzięły setki osób, m.in. bawiąc się przed eventem w gry tematyczne związane z umiejętnościami zawodników, uczestnicząc w konferencji prasowej z udziałem Macieja Janowskiego, Taja Woffindena i Maxa Fricke, czy podziwiając motocykl, na którym Woffinden sięgnął po Indywidualne Mistrzostwo Świata. " nr="05." img={data.okladka.childImageSharp.fluid}>

        <TytulPunkty tekst="W strefie kibica organizowanej przez nas podczas Betard Speedway Grand Prix zjawiły się  setki kibiców, zachęcanych grami, spotkaniem z zawodnikami i gadżetami zaprojektowanymi na tą  okazję. "  />


        <Slider_d foto={slider_d2} />

      

       

            
       
        </Rozdzial>
        
        </>
    )
}