import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import React from 'react'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'


export default function Research() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/speedway/1/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                znak: file(relativePath: { eq: "projekty/speedway/1/znak@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                },
                znak2: file(relativePath: { eq: "projekty/speedway/1/znak2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                },
                znak3: file(relativePath: { eq: "projekty/speedway/1/znak3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                },  
                komp1: file(relativePath: { eq: "projekty/speedway/1/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                },
                
                komp2: file(relativePath: { eq: "projekty/speedway/1/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                },
                maciek: file(relativePath: { eq: "projekty/magic/1/maciek@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }

                
            }
            `)
    return(
        <>

        <Rozdzial tytul="Identyfikacja wizualna" tekst="Dostosowanie wizualnej części marki do obecności w sporcie była niezbędna. Bardzo duża ilość aktywności okołosportowych firmy, w tym zaangażowanie w roli sponsora tytularnego Betard Sparty Wrocław, wymagała osobnych narzędzi. Inną kwestią była chęć uniknięcia zbyt dużej syntezy projektów. Szczególnie, gdy zaangażowanie firmy w sport jest czymś więcej niż komercyjną współpracą. Zobacz jak dostosowaliśmy platformę komunikacji do zaangażowania Betard 
w speedway." nr="01." img={data.okladka.childImageSharp.fluid}>
        <TytulPunkty tekst="Podstawowymi elementami platformy komunikacji są główne składowe identyfikacji marki Betard. W nowej, energicznej odsłonie, oddającej charakter sportu." tekst1="Charakterystyczny niebieski kolor, dobór typografii, element kwadratu i zastosowanie fotografii, pozostały fundamentem systemu wizualnego."
        tekst2="Symbol Speedway Partner powstał na bazie skojarzeń przechylonego kwadrata, oddającego prędkość i śladu opony nawiązującej do charakteru speedwaya." />
        <Img fluid={data.znak.childImageSharp.fluid} />
        <Img fluid={data.znak2.childImageSharp.fluid} />

        <Komp_kol img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} tytul1="System" tekst1="Kluczowe było dostosowanie systemu z bardzo mocnym nawiązaniem do obecnego wizerunku marki. W związku z tym, wiodącym kolorem 
w komunikacji pozostał kolor niebieski. Przełamany został czernią, która bezpośrednio nawiązuje do żużla i zastąpiła używany w materiałach korporacyjnych kolor biały. Wiodącym krojem jest Purista, który jest krojem nagłówkowym w identyfikacji Betard. Element kwadratu tworzy tu efekt szachownicy - symbolu flagi startowej i pozostaje istotną częścią identyfikacji."  />

<TytulPunkty tekst="Dzięki dedykowanym rozwiązaniom graficznym, marka pozostaje adekwatna dla wszystkich odbiorców i umiejętnie zarządza emocjami w swojej komunikacji."/>
   
<Img fluid={data.znak3.childImageSharp.fluid} />
        </Rozdzial>
        
        </>
    )
}