import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useRef } from 'react'
import { InView } from 'react-intersection-observer'
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import Formularz from '../../components/formularz/formularz'
import Intro from '../../components/projekty/comp/projekt_intro'
import Inne from '../../components/projekty/inne'
import Ocena from '../../components/projekty/OcenaProjektu'
import Strategia from '../../components/projekty/speedway/1'
import Identyfikacja from '../../components/projekty/speedway/2'
import Print from '../../components/projekty/speedway/3'
import WebDesign from '../../components/projekty/speedway/4'
import Digital from '../../components/projekty/speedway/5'
import Seo from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import back from '../../images/ikony/goback.svg'
import oko from '../../images/ikony/oko.svg'
import paluch from '../../images/ikony/paluch.svg'
import strzalka from '../../images/ikony/strzalka.svg'
import style from './projekt.module.css'







let title = "SPEEDWAY PARTNER"

export default function Betard() {


    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/speedway/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                okladka2: file(relativePath: { eq: "projekty/speedway/okladka2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                         
            }
            `)

    // polyfill();
    const slider = useRef(null)
    const wrapper = useRef(null)

    const r1 = useRef(null)
    const r2 = useRef(null)
    const r3 = useRef(null)
    const r4 = useRef(null)
    const r5 = useRef(null)
    const r6 = useRef(null)
    const r7 = useRef(null)


    useScrollPosition(
        ({ prevPos, currPos }) => {
        console.log(currPos.x)
        console.log(currPos.y)
        },
        [],
        [wrapper],
        false,
        300,
    )

        


    




        let settingss = {
            dots: false,
            adaptiveHeight: true,
            infinite: false,
            arrows: false,
            centerMode: false,
            speed: 300,
            slidesToShow: 3,
            variableWidth: true, 
          }    



    return (    
        <>
        <div style={{
            height: "calc(100vh - 60px)",
            overflow: "y-scroll",
        }}
        ref={wrapper}>
            <div id="projekt-bread" className="projekt-aktywny projekt-header">
                <div className="lista-item">
                    <TransitionLink style={{
                        left: '0',
                        top: '0',
                        position:'absolute',
                    }} to="/projekty">
                        <img className="projekt-header-cofnij" src={back} />
                    </TransitionLink>
                    <h3>{title}</h3>
                    <div className="projekt-zajawka">
                        <img src={oko} />
                        <span className="przycisk">2018-2021</span>
                        <div className="projekt-zajawka-kategorie">
                            <span className="przycisk">Strategia</span>
                            <span className="przycisk">media</span>
                            <span className="przycisk">print</span>
                        
                        </div>
                        
                        <p>Mianem Speedway Partnera, Betard określa swoje zaangażowanie w rozwój sportu żużlowego. We współpracy z działem marketingu tworzymy koncepcje i produkujemy komunikację wizualną.</p>
                        <span 
                        style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}
                        className="przycisk"><img src={paluch} style={{marginRight: '10px'}}/>ZOBACZ PROJEKT</span>
                    </div>

                    { <div className="bread-dodatkowe slider-rozdzialy">
                    <div className="rozdzialy-maska"></div>
                    <Slick  ref={slider} className="rozdzial-slider" {...settingss}>
                    
                        <span onClick={
                            () => { r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(0);}}
                            className="przycisk">01. IDENTYFIKACJA MARKI</span>
                        <span onClick={
                            () => { r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(1);}}
                        
                        
                        className="przycisk">02. PRINT</span>  
                        <span onClick={
                            () => { r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(2);}} 
                       className="przycisk">03. ILUSTRACJA</span>  
                        <span onClick={
                            () => { r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(3);}}
                        className="przycisk">04. DIGITAL DESIGN</span>  
                         
                        <span onClick={
                            () => { r5.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(4);}}
                        
                        className="przycisk">05. EVENT</span>  
                        
            
                        
                    </Slick>
                    <div className="rozdzialy-maska"></div>
                    </div>}

                </div>

            </div>


            <Seo title={title}  description="Mianem Speedway Partnera firma Betard określa swoje zaangażowanie w rozwój sportu żużlowego. W ramach prowadzonych działań indywidualnie wspiera zawodników, w tym czołowych reprezentantów Betard Sparty Wrocław i liderów corocznych Indywidualnych Mistrzostw Świata. My, we współpracy z działem marketingu Betard, odpowiadamy za tworzenie koncepcji, produkcję  materiałów i komunikację." image={data.okladka.childImageSharp.fluid} />
            <div className="podstrona-wrapper projekt-padding">

            <Img style={{width: '100%'}} fluid={data.okladka.childImageSharp.fluid} />

            <Intro tytul="Podsumowanie projektu" tekst="Mianem Speedway Partnera firma Betard określa swoje zaangażowanie w rozwój sportu żużlowego. W ramach prowadzonych działań indywidualnie wspiera zawodników, w tym czołowych reprezentantów Betard Sparty Wrocław i liderów corocznych Indywidualnych Mistrzostw Świata. My, we współpracy z działem marketingu Betard, odpowiadamy za tworzenie koncepcji, produkcję  materiałów i komunikację." tekst1="Stworzyliśmy dedykowaną platformę  komunikacji  marki Betard, dostosowaną do obecności firmy w sporcie żużlowym. " tekst2='Tworzyliśmy m.in. szatę i materiały graficzne dla trzech edycji wrocławskiego Betard Speedway Grand Prix a także zawodników takich jak Maciej Janowski, Taj Woffinden czy Gleb Chugunov.' />

            <Img style={{width: '100%'}} fluid={data.okladka2.childImageSharp.fluid} />








            <div className={style.architektura}>
                <h3>ARCHITEKTURA PROJEKTU</h3>
                <div>

               
                        <div onClick={() => r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   } data-rozdzial={1}>
                            <span className="przycisk">
                                01.IDENTYFIKACJA MARKI
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={2}>
                            <span className="przycisk">
                                02. PRINT
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={3}>
                            <span className="przycisk">
                                03. ILUSTRACJA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>
                        
                        <div onClick={() => r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={4}>
                            <span className="przycisk">
                                04. DIGITAL DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                       
                        <div onClick={() => r5.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={5}>
                            <span className="przycisk">
                                05. EVENT
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>


                      

                       


           
                </div>
            </div>
            </div>


            <InView  as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('0')}>
            <div ref={r1}><Strategia  /></div>
            </InView>

            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('1')}>
            <div ref={r2}><Identyfikacja /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('2')}>
            <div ref={r3}><Print  /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('3')}>
            <div ref={r4}><WebDesign /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('4')}>
            <div ref={r5}><Digital /></div>
            </InView>

 
          

            <div className="podstrona-wrapper">
            <Ocena tekst='"Jednym z głównych atutów Firmy Odra Studio jest łączenie w sobie dopasowania się do potrzeb rynkowych jednoczenie kreując wizję klienta. Młody zespół zdolnych ludzi potrafi działać pod presją czasu absolutnie nie tracąc przy tym profesjonalnego efektu końcowego. Są otwarci na pomysły, które potrafią w kreatywny sposób rozwinąć tak aby osiągnąć zamierzony cel marketingowy. Dzięki swojej znajomości rynku są również skorzy do negowania działań, które ich zdaniem są dla klienta niekorzystne lub nie przyniosą wymiernych efektów. W zamian proponując nam rozwiązania, które okazały się sukcesem."' firma="Reprezentant Polski na żużlu" imie="Maciej Janowski" />

            <Slider  uslugi={true} />
            </div>

            <Inne omin="Speedway Partner" />


            


            <Formularz />
            </div>
      
         
        </> 
    )
}