import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import ReactPlayer from "react-player"
import Komp_zdjecie from '../comp/kol_tekst'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Intro from '../comp/projekt_intro'
import Punkt from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import SliderDuzy from '../comp/slider_duzy'
import Slider from '../comp/slider_foto'
import TytulPunkty from '../comp/tytul_punkty'

export default function Strategia() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/speedway/2/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                },
                paczka: file(relativePath: { eq: "projekty/speedway/2/paczka@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                paczka2: file(relativePath: { eq: "projekty/speedway/2/paczka2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                plakaty: file(relativePath: { eq: "projekty/speedway/2/plakaty@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },

                slider1: file(relativePath: { eq: "projekty/magic/2/slider1@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                slider2: file(relativePath: { eq: "projekty/magic/2/slider2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                slider3: file(relativePath: { eq: "projekty/magic/2/slider3@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
         
                maciek: file(relativePath: { eq: "projekty/magic/2/maciek@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                maciek2: file(relativePath: { eq: "projekty/magic/2/maciek2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                brandbook: file(relativePath: { eq: "projekty/magic/2/brandbook@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },


                slider_1: file(relativePath: { eq: "projekty/speedway/2/slider/1.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider_2: file(relativePath: { eq: "projekty/speedway/2/slider/2.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider_3: file(relativePath: { eq: "projekty/speedway/2/slider/3.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider_4: file(relativePath: { eq: "projekty/speedway/2/slider/4.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider_5: file(relativePath: { eq: "projekty/speedway/2/slider/5.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider_6: file(relativePath: { eq: "projekty/speedway/2/slider/6.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider_7: file(relativePath: { eq: "projekty/speedway/2/slider/7.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider_8: file(relativePath: { eq: "projekty/speedway/2/slider/8.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }

                slider2_1: file(relativePath: { eq: "projekty/speedway/2/slider2/1.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider2_2: file(relativePath: { eq: "projekty/speedway/2/slider2/2.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider2_3: file(relativePath: { eq: "projekty/speedway/2/slider2/3.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider2_4: file(relativePath: { eq: "projekty/speedway/2/slider2/4.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider2_5: file(relativePath: { eq: "projekty/speedway/2/slider2/5.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider2_6: file(relativePath: { eq: "projekty/speedway/2/slider2/6.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                slider2_7: file(relativePath: { eq: "projekty/speedway/2/slider2/7.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }

                slider_d1: file(relativePath: { eq: "projekty/speedway/2/slider3/1@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1300, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                slider_d2: file(relativePath: { eq: "projekty/speedway/2/slider3/2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1300, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                slider_d3: file(relativePath: { eq: "projekty/speedway/2/slider3/3@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1300, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                wheelie: file(relativePath: { eq: "projekty/speedway/2/wheelie@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                komp1: file(relativePath: { eq: "projekty/speedway/2/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                komp2: file(relativePath: { eq: "projekty/speedway/2/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                komp3: file(relativePath: { eq: "projekty/speedway/2/komp3@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                komp4: file(relativePath: { eq: "projekty/speedway/2/komp4@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1500, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                full: file(relativePath: { eq: "projekty/speedway/2/full@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                full2: file(relativePath: { eq: "projekty/speedway/2/full2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                full3: file(relativePath: { eq: "projekty/speedway/2/full3@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                full4: file(relativePath: { eq: "projekty/speedway/2/full4@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },

                full5: file(relativePath: { eq: "projekty/speedway/2/full5@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                full6: file(relativePath: { eq: "projekty/speedway/2/full6@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                cement: file(relativePath: { eq: "projekty/speedway/2/cement@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
              
               
                    

               

            }
            `)

    


            const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed, data.slider_5.childImageSharp.fixed, data.slider_6.childImageSharp.fixed, data.slider_7.childImageSharp.fixed, data.slider_8.childImageSharp.fixed]
    
            const foto2 = [data.slider2_1.childImageSharp.fixed, data.slider2_2.childImageSharp.fixed, data.slider2_3.childImageSharp.fixed, data.slider2_4.childImageSharp.fixed, data.slider2_5.childImageSharp.fixed, data.slider2_6.childImageSharp.fixed, data.slider2_7.childImageSharp.fixed]


            const foto3 = [data.slider_d1.childImageSharp.fluid, data.slider_d2.childImageSharp.fluid, data.slider_d3.childImageSharp.fluid]
    
    
    return(
        <>
        <Rozdzial tytul="PRINT" invert={true} tekst="W obliczu skali zaangażowania firmy w działania związane z żużlem i rodzajem nośników wynikających z dużej ilości działań offline, materiały drukowane, gadżety czy elementy widowiska były dużą częścią prac studia. W ramach projektu tworzyliśmy koncepcje elementów komunikacyjnych, odpowiadaliśmy za ich projekty oraz produkcję." nr="02." img={data.okladka.childImageSharp.fluid}>


        <Intro tytul="Paczka sponsorska" tekst="Plakaty, publikacje, odzież, gadżety czy elementy reklamy outdoorowej - w ciągu 3 lat zaprojektowaliśmy setki materiałów oznaczonych logo Speedway Partner. " />

        <Img fluid={data.paczka.childImageSharp.fluid} />

        <Slider foto={foto} tytul="Zawartość paczki" />

        <Intro tytul="Paczka sponsorska" tekst="Stworzyliśmy m.in. dedykowane paczki gadżetów dla partnerów biznesowych Betard oraz zestawy brandingowe dla sportowców #TeamBetard." />

        <Img fluid={data.paczka2.childImageSharp.fluid} />

        <Slider foto={foto2} tytul="Zawartość paczki" />

        <Intro tytul="Paczka sponsorska" tekst="Tworzymy dedykowane rozwiązania - odpowiadamy za koncepcję, projekt oraz kompleksowo obsługujemy proces produkcji by dostarczyć  Ci produkty najwyższej jakości." />

        <Img fluid={data.plakaty.childImageSharp.fluid} />

        <Punkt tytul="Publikacja dla PARTNERÓW" tekst="Stworzyliśmy dedykowaną publikację dla gości specjalnych podczas meczów Betard Sparty Wrocław czy wrocławskich edycji Speedway Grand Prix. Jej celem jest wprowadzenie partnerów i kibiców
        w świat speedwaya i firmy Betard. " />

        <SliderDuzy foto={foto3} />

        <TytulPunkty tekst="W ramach wspólnych działań z firmą  Betard otrzymaliśmy m.in. szansę  zaprojektowania szaty graficznej dla trzech edycji FIM Betard Speedway Grand Prix we Wrocławiu. "/>
        
        <Img fluid={data.wheelie.childImageSharp.fluid} />

        <Komp_kol img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} tytul1="BETARD SPEEDWAY GRAND PRIX" tekst1="Zaprojektowane przez nas elementy służyły do komunikacji wydarzenia w mediach, promocji offline a także wykorzystywane były w oficjalnych wydawnictwach cyklu Speedway Grand Prix."  />

        <Img fluid={data.full.childImageSharp.fluid} />

        <Intro tytul="Gadżety kibicowskie" tekst="Zwycięzcą  wrocławskiej edycji Mistrzostw Świata został Maciej Janowski, za którego komunikację odpowiadamy w Odrze. Zobacz więcej w naszych projektach!" />

        <Komp_kol img1={data.komp3.childImageSharp.fluid} img2={data.komp4.childImageSharp.fluid} tytul1="KALENDARZ SPEEDWAY" tekst1="Jednym z wydawnictw 2021 był dedykowany kalendarz żużlowy, stworzony w oparciu o materiały pochodzące z wrocławskiej edycji Speedway Grand Prix, wspieranej przez firmę Betard. "  />

        <Img fluid={data.full2.childImageSharp.fluid} />

        <Intro tytul="Gadżety kibicowskie" tekst="By móc aktywnie rozprzestrzeniać  miłość do żużla, niezbędne jest poważne zaplecze gadżetowe. Breloki, magnesy, plakaty, fankarty czy podkładki pod mysz - Betard ma wszystko czego potrzebuje." />

        <Img fluid={data.full3.childImageSharp.fluid} />
        <Img fluid={data.full4.childImageSharp.fluid} />
        
        <Intro tytul="Obecność offline" tekst="Obecność marki online wymaga odpowiedniej ekspozycji. Realizowaliśmy elementy miasteczka marki podczas Speedway Grand Prix i ekspozycję  podczas meczów ligowych." />

        <Img fluid={data.full5.childImageSharp.fluid} />
        <Img fluid={data.full6.childImageSharp.fluid} />

        <Intro tytul="Obecność offline" tekst="Przywiązanie firmy  do sportu żużlowego wychodzi znacznie poza stadion. Ilustrowani żużlowcy są  charakterystycznym elementem floty technicznej i osobowej Betard." />

        <Komp_zdjecie img={data.cement.childImageSharp.fluid} tekst="W 2020 roku, na kanwie sukcesu obecności marki podczas pierwszej od 17 lat edycji Indywidualnych Mistrzostw Świata. ilustracje stworzone na tę okazję trafiły między innymi na naczepy potężnych cementowozów." tytul="Cementowozy" />

        <div>
        <div className="wrapper_player" >
            <ReactPlayer
            url="https://www.facebook.com/BetardPrefabrykaty/videos/231692394832761"

            className='player'
            width='100%'
            height='100%'
            controls={false}
            />
        </div>
        </div>
        
        
        
        
        </Rozdzial>
        
        </>
    )
}