import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Intro from '../comp/projekt_intro'
import Rozdzial from '../comp/rozdzial'
export default function Identyfikacja() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/magic/3/slider1@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/magic/3/slider2@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/magic/3/slider3@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/magic/3/slider4@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/speedway/3/okladka@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full: file(relativePath: { eq: "projekty/speedway/3/full1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full2: file(relativePath: { eq: "projekty/speedway/3/full2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full3: file(relativePath: { eq: "projekty/speedway/3/full3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                maciek: file(relativePath: { eq: "projekty/magic/3/maciek@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                maciek2: file(relativePath: { eq: "projekty/magic/3/maciek2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                bus1: file(relativePath: { eq: "projekty/magic/3/bus1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                bus2: file(relativePath: { eq: "projekty/magic/3/bus2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                

                komp1: file(relativePath: { eq: "projekty/speedway/3/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/speedway/3/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp3: file(relativePath: { eq: "projekty/magic/3/komp3@2x.jpg"}) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp4: file(relativePath: { eq: "projekty/magic/3/komp4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komp5: file(relativePath: { eq: "projekty/magic/3/komp5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                plakat1: file(relativePath: { eq: "projekty/magic/3/plakat1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                plakat2: file(relativePath: { eq: "projekty/magic/3/plakat2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                plakat3: file(relativePath: { eq: "projekty/magic/3/plakat3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]
    const plakaty = [data.plakat1.childImageSharp.fluid, data.plakat2.childImageSharp.fluid, data.plakat3.childImageSharp.fluid]
    
    
    return(
        <>

        <Rozdzial tytul="ILUSTRACJA" invert={false} tekst="Ilustracje żużlowe to język wizualny stworzony na potrzeby wrocławskiej edycji Indywidualnych Mistrzostw Świata w 2020 roku, których sponsorem tytularnym od tego czasu jest firma Betard. Zastosowanie ilustracji pozwoliło nam na zoptymalizować proces tworzenia materiałów, niezbędnych dla promocji firmy i komunikacji wydarzenia. To ważne, ze względu na bardzo krótki czas realizacji i wymagania projektu, który zakładał m.in. stworzenie miasteczka dla kibiców, reklamę outdoorową i serię gadżetów." nr="03." img={data.okladka.childImageSharp.fluid}>

        <Intro tytul="Obecność offline" tekst="Pełna kontrola nad tworzonymi elementami była fundamentem projektu. Dzięki wykorzystaniu ilustracji, mogliśmy efektywnie zarządzać  projektowanymi materiałami." />

        <Img fluid={data.full.childImageSharp.fluid} />
        <Img fluid={data.full2.childImageSharp.fluid} />

        <Komp_kol tytul1="ZOBACZ WIĘCEJ" tekst1="Więcej zastosowań ilustracji w projekcie Speedway Partner znajdziesz w zakładce Print. "  img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} invert={true}  />

        <Img fluid={data.full3.childImageSharp.fluid} />

        
       
        
        

        
        
        </Rozdzial>
        
        </>
    )
}