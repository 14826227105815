import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Intro from '../comp/projekt_intro'
import Rozdzial from '../comp/rozdzial'
import Slider from '../comp/slider_foto'
import sgp from './sgp.mp4'

export default function Ilustracja() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/speedway/4/slider/1@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/speedway/4/slider/2@2x.jpg" }) {
                childImageSharp {
                    fixed(width: 360, height: 360) {
                        ...GatsbyImageSharpFixed
                    }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/speedway/4/slider/3@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/betard/4/slider/4@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/speedway/4/okladka@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full: file(relativePath: { eq: "projekty/speedway/4/full@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full2: file(relativePath: { eq: "projekty/speedway/4/full2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty: file(relativePath: { eq: "projekty/betard/4/skala_produkty@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty_animacja: file(relativePath: { eq: "projekty/betard/4/produkty_animacja@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                przeswietlenia: file(relativePath: { eq: "projekty/betard/4/przeswietlenia@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                ilustracja1: file(relativePath: { eq: "projekty/betard/4/ilustracja1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                ilustracja2: file(relativePath: { eq: "projekty/betard/4/ilustracja2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                tablet: file(relativePath: { eq: "projekty/magic/4/tablet@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komputer: file(relativePath: { eq: "projekty/magic/4/komputer@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                mobile: file(relativePath: { eq: "projekty/magic/4/mobile@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                
                komp1: file(relativePath: { eq: "projekty/magic/4/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                postac: file(relativePath: { eq: "projekty/magic/4/postac@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                

            }
            `)

    


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed]
    
    
    return(
        <>

        <Rozdzial tytul="DIGITAL DESIGN" invert={true} tekst="Działania żużlowe firmy wymagają efektywnej obecności w przestrzeni cyfrowej. Komunikacja Speedway Partner odbywa się poprzez dedykowaną zakładkę na stronie internetowej firmy, w mediach społecznościowych, w formie mailingowej a także poprzez dedykowane animacje, eksponowane w trakcie widowisk sportowych." nr="04." img={data.okladka.childImageSharp.fluid}>
        
        <Intro tytul="Obecność offline" tekst="Nasz sprawdzony proces tworzenia rozwiązań cyfrowych sprawił, że strona mogła powstać  w szybkim tempie, jednocześnie zachowując swoją  unikalność i atrakcyjność."/>

        <Img fluid={data.full.childImageSharp.fluid} />

        <Intro tytul="Obecność offline" tekst="Dzięki zastosowanym rozwiązaniom tworzenie materiałów graficznych jest szybkie a efekt w bardzo satysfakcjonujący sposób realizuje założenia firmy."/>
        
    
        <Slider foto={foto} tytul="Posty meczowe" />

        <video style={{width: '100%', paddingTop: '50px' }} autoPlay muted playsInline loop>
                 <source src={sgp} type="video/mp4" />
        </video>
        
        </Rozdzial>
        
        </>
    )
}